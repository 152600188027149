var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"container_order pa-5"},[_c('div',{staticClass:"order"},[_c('div',{staticClass:"order_table"},[_c('div',{staticClass:"row header_history no-gutters"},_vm._l((_vm.$t('ORDER_HISTORY.order_table')),function(item){return _c('div',{key:item,staticClass:"col-sm-2 h_name"},[_vm._v(_vm._s(item))])}),0),_vm._l((_vm.orderHistoryItems?.data),function(item,index){return _c('div',{key:index,staticClass:"row body_history no-gutters"},[_c('div',{staticClass:"col-sm-2 text_u"},[_vm._v(" №"+_vm._s(item.id)+" ")]),_c('div',{staticClass:"col-sm-2 text_grey text_u"},[_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleDateString())+" ")]),_c('div',{staticClass:"col-sm-2 text_grey"},[_vm._v(" "+_vm._s(item.orderStatus || '-- статус заказа --')+" ")]),_c('div',{staticClass:"col-sm-2 text_l"},[_vm._v(" "+_vm._s(item.sum)+" ")]),_c('div',{staticClass:"col-sm-4 text_l"},[_c('div',{staticClass:"history_actions"},[_c('div',{staticClass:"icon_c cursor-pointer",on:{"click":function($event){return _vm.$router.push({
                  name: 'order history details',
                  params: { id: item.id },
                })}}},[_c('span',{staticClass:"order_look"},[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("mdi-eye")])],1),_c('span',[_vm._v("view")])]),_c('div',{staticClass:"icon_c cursor-pointer",on:{"click":function($event){return _vm.$router.push({
                  name: 'adming basket',
                  query: { orderId: item.id },
                })}}},[_c('span',{staticClass:"order_look_r"},[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("mdi-refresh")])],1),_c('span',[_vm._v("Повторить")])])])])])})],2),_c('nav',{staticClass:"py-4 me-4 mb-16",attrs:{"aria-label":"Page navigation example"}},[_c('ul',{staticClass:"pagination justify-content-end"},[_c('li',{staticClass:"page-item disabled"},[_c('a',{staticClass:"page-link",attrs:{"href":"#","tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.goToPage(_vm.currentPage - 1)}}},[_vm._v("Previous")])]),_vm._l((Array.from(
            {
              length: _vm.orderHistoryItems?.last_page,
            },
            (_, index) => index + 1
          )),function(page,index){return _c('li',{key:index,staticClass:"page-item"},[_c('a',{staticClass:"page-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goToPage(page)}}},[_vm._v(_vm._s(page))])])}),_c('li',{staticClass:"page-item"},[_c('a',{staticClass:"page-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goToPage(2)}}},[_vm._v("Next")])])],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }